
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductNotFound',
  computed: {
    productNotFoundMessage() {
      return process.env.VUE_APP_PRODUCT_NOT_FOUND_MESSAGE;
    },
  },
});
